<template>
  <div class="title--page">
    <div class="title--page__wrapper">
      <div class="title--page__header">
        <h1>Отчет&nbsp;по&nbsp;сервису&nbsp;трансляции<br/>
          рич-контента&nbsp;24stream</h1>
      </div>
      <div class="title--page__period">
        <h2 class="licenses">{{ licensesList }} - 24TTL</h2>
        <h2>({{ dateRange }})</h2>
      </div>
      <div class="title--page__deliver">
        Исполнитель:<br/>
        ООО "<span v-if="stampType === 'TTL'">ТТЛ</span> <span v-else>24ТТЛ</span>"<br/>
        Генеральный директор<br/>
      </div>
      <div class="title--page__sign">
        <img v-if="isStamp && stampType === '24TTL'" alt="TTL stamp" style="width: 202px;" src="@/assets/vendor/report/stamp.png">
        <img v-if="isStamp && stampType === 'TTL'" alt="24TTL stamp" style="width: 131px;" src="@/assets/vendor/report/stamp24.png">
        __________________ / <span v-if="stampType === '24TTL'">М.О. Антонова</span> <span v-else>М.О. Антонова</span>/<br/>
        м.п.
      </div>
    </div>
  </div>
</template>

<script>
import HelperUser from "@/services/Helpers/HelperUser";
import moment from "moment";
import 'moment/locale/ru'

moment.locale('ru')

export default {
  name: "TitlePage",
  props: {
    isStamp: {
      type: Boolean,
      default: false
    },
    stampType: {
      type: String,
      default: 'TTL'
    },
    timeLocal: {
      type: Object,
      default: null
    },
    licensesIds: {
      type: Array,
      default: null
    }
  },
  computed: {
    licensesList() {
      let licenses = [...HelperUser.getUserLicensesRetailers().licenses.data]
      licenses = licenses.filter(elem => this.licensesIds.find(id => id === elem.id))
      let licensesList = ""
      for (const license in licenses) {
        licensesList += licenses[license].name + ", "
      }
      licensesList = licensesList.slice(0, -2)
      return licensesList
    },
    dateRange() {
      const gte = new Date(this.timeLocal.gte)
      const lte = new Date(this.timeLocal.lte)

      let finalDate = ""
      if (gte.getMonth() === lte.getMonth() && gte.getFullYear() === lte.getFullYear() && gte.getDay() === lte.getDay()) {
        const date = moment(gte).format('DD MMMM YYYY')
        finalDate = date.charAt(0).toUpperCase() + date.slice(1)
      } else {
        const start = moment(gte).format('DD MMMM YYYY')
        const finish = moment(lte).format('DD MMMM YYYY')
        finalDate = start.charAt(0).toUpperCase() + start.slice(1) + " - " + finish.charAt(0).toUpperCase() + finish.slice(1)
      }
      return finalDate
    }
  },
}
</script>

<style lang="scss">
.title--page {
  width: 100%;
  height: 1050px;
  background-color: white;
  padding: 0 100px 0;
  display: flex;
  color: #404a53;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__header {
    margin-bottom: 70px;
  }

  &__period {
    margin-bottom: 70px;

    .licenses {
      font-size: 30px;
    }
  }

  &__deliver {
    margin-bottom: 120px;
    font-weight: bold;
  }

  &__sign {
    position: relative;

    img {
      position: absolute;
      top: -100px;
      width: auto;
    }
  }
}
</style>
